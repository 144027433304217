<template>
  <div class="login">
    <div class="title">{{ passLoginFlag ? "密码登录" : "验证码登录" }}</div>
    <!-- 手机号 -->
    <div class="iphone">
      <span class="iphone_icon"></span>
      <span class="iphone_number">{{ userInfo.phone }}</span>
    </div>
    <!-- 验证码 -->
    <div class="verif" v-if="!passLoginFlag">
      <span class="icon"></span>
      <input class="number" v-model="verificationCode" type="text" maxlength="5" placeholder="请输入手机验证码" />
      <span v-if="codeShow" class="acquire_code" @click="onGetCode">获取验证码</span>
      <span v-if="!codeShow" class="acquire_code_time" @click="onGetCode">{{ count }}秒后重试</span>
    </div>
    <!-- 密码 -->
    <div class="verif" v-if="passLoginFlag">
      <span class="icon"></span>
      <input class="number" v-model="password" type="text" placeholder="请输入密码" />
    </div>
    <!-- 切换登录方式 -->
    <div class="password pass">
      <img class="pass_agree" @click="onPassAgree(passimgSrc)" :src="passimgSrc" alt />
      <span class="hotZone">密码登录</span>
    </div>
    <div class="protocol">
      <img class="protocol_agree" @click="onAgree(imgSrc)" :src="imgSrc" alt />
      <span class="protocol_agree_content">
        <span class="hotZone" @click="onAgree(imgSrc)">阅读并接受</span>
        <span class="agreement" @click="onToAgreement(8)">《用户协议》</span>
        <span class="agreement" @click="onToAgreement(2)">《隐私协议》</span>
      </span>
    </div>
    <button :class="{
      login: true,
      is_login: passLoginFlag === false ? isLogin : password ? true : false,
    }" @click="onLogin">登录</button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import md5 from 'js-md5'
import UserModel from '@/models/user'
const userModel = new UserModel()
const { mapGetters } = createNamespacedHelpers('user')

export default {
  data() {
    return {
      isLogin: false,
      verificationCode: '', //验证码
      password: '', // 密码
      codeShow: true,
      imgSrc: require('../../assets/images/unselected@3x.png'),
      passimgSrc: require('../../assets/images/unselected@3x.png'),
      passLoginFlag: false, // 密码登录标识符
      timer: null, //定时器
      count: '', //获取验证码倒计时时间
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    verificationCode(code) {
      if (code) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    passLoginFlag(value) {
      if (value === true) {
        this.verificationCode = ''
      } else {
        this.password = ''
      }
    },
  },
  mounted() {
    //获取数据
    this.info = this.$store.state.user
  },
  methods: {
    //用户协议和隐私协议
    onToAgreement(type) {
      this.$router.push(`/agreement?type=${type}`)
    },
    onPassAgree(src) {
      this.passLoginFlag = !this.passLoginFlag
      if (src.indexOf('un') > -1) {
        this.passimgSrc = require('../../assets/images/selected@3x.png')
      } else {
        this.passimgSrc = require('../../assets/images/unselected@3x.png')
      }
    },
    //切换选中状态
    onAgree(src) {
      if (src.indexOf('un') > -1) {
        this.imgSrc = require('../../assets/images/selected@3x.png')
      } else {
        this.imgSrc = require('../../assets/images/unselected@3x.png')
      }
    },
    //登录事件
    onLogin() {
      if (!this.isLogin && !this.passLoginFlag) {
        this.$toast('请填写手机验证码', 'warning', 1000)
      } else if (this.passLoginFlag && !this.password) {
        this.$toast('请填写密码', 'warning', 1000)
      } else if (this.imgSrc.indexOf('un') > -1) {
        this.$toast('请阅读并同意《用户协议》《隐私协议》', 'warning', 1000)
      } else {
        this._signIn()
      }
    },
    //调用登录接口
    _signIn() {
      userModel
        .login({
          userId: this.userInfo.id,
          verifyCode: this.verificationCode,
          type: this.passLoginFlag ? 1 : 2, // 密码登录传递1，验证码是2
          password: this.password,
        })
        .then((res) => {
          if (res.status == 0) {
            localStorage.clear()
            this.$store.commit('user/AUTH', res.data.token)
            this.$store.commit('user/USER', {
              id: res.data.userId,
              name: res.data.name,
              phone: res.data.phone,
            })
            if (this.$route.query.redirect_uri) {
              window.location.href = decodeURIComponent(
                this.$route.query.redirect_uri
              )
            } else {
              window.location.href = '/'
            }
          }
        })
    },
    // 获取验证码
    onGetCode() {
      userModel
        .getVerificationCode(
          JSON.stringify({
            userId: this.info.userId,
            checkKey: md5(this.info.userPhone + this.info.userId),
          })
        )
        .then((res) => {
          if (res.status == 0) {
            this._countDown()
          }
        })
    },
    //验证码倒计时功能
    _countDown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.codeShow = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.codeShow = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
  },
}
</script>
<style lang='stylus' scoped>
@import './login.styl'
</style>
