import HTTP from '@/utils/http'

class UserModel extends HTTP {
  /**
   * 获取验证码
   */
  getVerificationCode(data) {
    return this.post({
      url: '/usercenter/common/h5/verifycode',
      data,
    })
  }
  /**
   * 手机号验证码登录
   */
  login(data) {
    return this.post({
      url: '/usercenter/user/h5/login',
      data,
    })
  }
  /**
   *获取订单
   */
  getOrder(data) {
    return this.post({
      url: '/ordercore/list/v1',
      data,
    })
  }
}
export default UserModel
